.fwb {
  font-weight: $font-weight-bold;
}

.fwm {
  font-weight: $font-weight-medium;
}

.fz14 {
  font-size: 14px;
}

.fz20 {
  font-size: 20px;
}

.gray-c {
  color: $dark-gray;
}

.head {
  padding: 16px 0 0;
  font-weight: $font-weight-medium;
  @media screen and (max-width: 959px) {
    background: $dark-blue;
    padding: 12px 0;
  }
  &__inner {
    @media screen and (max-width: 959px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__first-block {
    margin-bottom: 24px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 959px) {
      margin-bottom: 0;
    }
  }
  &__logo {
    display: block;
    color: $black;
    &:hover {
      color: $dark-blue;
    }
    @media screen and (max-width: 1199px) {
      max-width: 160px;
    }
    @media screen and (max-width: 959px) {
      color: $white;
      max-width: 107px;
    }
  }
  &__short-desc {
    font-size: 18px;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 1199px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1099px) {
      display: none;
    }
  }
  &__menu {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    a {
      color: $black;
      &:hover {
        text-decoration: none;
        color: $dark-blue;
      }
    }
    li:not(:last-child) {
      margin-right: 64px;
      @media screen and (max-width: 1199px) {
        margin-right: 48px;
      }
    }
    .corp-link {
      font-weight: $font-weight-bold;
      color: $purple;
    }
  }
  &__contacts {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: 48px;
        @media screen and (max-width: 959px) {
          margin-left: 24px;
        }
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: all .3s ease;
    color: $dark-blue;
    &:hover {
      text-decoration: none;
      color: $purple;
    }
    @media screen and (max-width: 959px) {
      color: $white;
    }
  }
  &__cart {
    position: relative;
  }
  &__cart-count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 16px;
    height: 16px;
    right: -8px;
    top: -4px;
    background: $blue;
    color: $white;
    font-size: 12px;
    font-weight: $font-weight-bold;
    border-radius: 50%;
    overflow: hidden;
  }
  &__link-icon {
    min-width: 20px;
    max-width: 20px;
    @media screen and (max-width: 959px) {
      min-width: 32px;
      max-width: 32px;
    }
  }
  &__catalog {
    margin-top: 20px;
  }
  &__catalog-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      flex: 1 1 0;
    }
    a {
      display: block;
      text-align: center;
      padding: 12px 16px;
      background: $dark-blue;
      color: $white;
      &:hover {
        text-decoration: none;
      }
    }
    a.uk-open {
      background: $blue;
    }
  }
}

.footer {
  color: $white;
  &__main {
    padding: 40px 0 80px;
    background: $dark;
    @media screen and (max-width: 959px) {
      padding: 32px 0 40px;
    }
  }
  &__logo {
    color: $blue;
    margin-bottom: 32px;
    @media screen and (max-width: 639px) {
     max-width: 206px;
    }
  }
  &__menu {
    h3 {
      font-weight: $font-weight-bold;
      margin-bottom: 24px;
      color: $white;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li:not(:last-child) {
      margin-bottom: 16px;
    }
    a {
      color: $white;
    }
  }
  &__contacts {
    padding: 0;
    list-style: none;
    li {
      display: flex;
      > img, svg {
        min-width: 20px;
        max-width: 20px;
        color: $purple;
        height: 20px;
      }
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    a {
      display: flex;
      align-items: center;
      font-weight: $font-weight-medium;
      white-space: nowrap;
      color: $white;
      > img, svg {
        min-width: 20px;
        max-width: 20px;
        color: $purple;
        height: 20px;
      }
    }
  }
  &__copyright {
    background: $dark-blue;
    padding: 10px 0;
    font-size: 14px;
    @media screen and (max-width: 959px) {
      padding: 16px 0;
    }
    a {
      text-decoration: underline;
      color: $white;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.footer-accordion {
  @media screen and (max-width: 639px) {
    h3 {
      font-size: 16px;
      border-bottom: 2px solid $light-blue;
      margin-bottom: 0;
      line-height: 24px;
      position: relative;
      &::after {
        content: url("../img/icons/arrow.svg");
        position: absolute;
        right: 0;
        top: 0;
        height: 24px;
      }
    }
    .accordion-content {
      padding: 24px 0 8px;
    }
    .uk-open h3::after {
      transform: rotate(-180deg);
    }
  }
}

.breadcrumbs-wrapper {
  padding: 24px 0;
  overflow: hidden;
  @media screen and (max-width: 959px) {
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px 0;
  }
  @media screen and (max-width: 639px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.page-404 {
  &__img {
    margin-bottom: 48px;
    @media screen and (max-width: 639px) {
      margin-bottom: 32px;
    }
  }
  &__title {
    font-size: 48px;
    line-height: 54px;
    font-weight: $font-weight-bold;
    color: $dark-blue;
    margin-bottom: 16px;
    @media screen and (max-width: 639px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__desc {
    margin-bottom: 48px;
    @media screen and (max-width: 639px) {
      margin-bottom: 32px;
    }
  }
}

.faq-accordion {
  > li {
    padding: 20px;
    border-radius: 8px;
    background: $light-blue;
    @media screen and (max-width: 639px) {
      padding: 12px 16px;
    }
  }
  .uk-accordion-title {
    font-size: 20px;
    font-weight: $font-weight-bold;
    padding-right: 32px;
    position: relative;
    &::after {
      content: url("../img/icons/arrow-b.svg");
      position: absolute;
      right: 0;
      top: calc(50% - 12px);
      height: 24px;
    }
    @media screen and (max-width: 639px) {
      font-size: 16px;
    }
  }
  .uk-open .uk-accordion-title::after {
    transform: rotate(-180deg);
  }
  .uk-accordion-content {
    padding: 16px 0;
  }
}

.contacts {
  &__map {
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid $dark-blue;
    overflow: hidden;
    min-height: 410px;
    max-height: 410px;
    @media screen and (max-width: 639px) {
      min-height: 288px;
      max-height: 288px;
    }
  }
  &__point {
    h4 {
      color: $dark-blue;
      margin-bottom: 0;
    }
  }
  &__footnote {
    padding: 12px 24px;
    background: $light-blue;
    border-radius: 8px;
    margin-top: 24px;
    @media screen and (max-width: 639px) {
      padding: 16px;
      margin-top: 16px;
    }
  }
  &__item {
    font-size: 20px;
    a {
      color: $black;
      &:hover {
        color: $dark-blue;
        text-decoration: none;
      }
    }
  }
}

.columns-2 {
  column-gap: 24px;
  column-count: 2;
  > * {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  @media screen and (max-width: 959px) {
    column-count: 1;
  }
}

.about {
  &__head {
    position: relative;
    margin-bottom: 24px;
    @media screen and (max-width: 639px) {
      margin-bottom: 16px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 37.87%, transparent);
    }
    h1 {
      position: absolute;
      left: 40px;
      bottom: 32px;
      z-index: 2;
      color: $white;
      margin: 0;
      @media screen and (max-width: 639px) {
        left: 16px;
        bottom: 20px;
      }
    }
  }
  &__banner {
    padding: 80px 64px;
    background: url("../img/decor/about-bg.jpg") no-repeat 75% 50% / cover;
    @media screen and (max-width: 959px) {
      padding: 48px 32px;
    }
    @media screen and (max-width: 639px) {
      padding: 40px 16px;
    }
  }
  &__banner-text {
    max-width: 588px;
    color: $white;
    font-size: 20px;
    line-height: 32px;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 959px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media screen and (max-width: 639px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.about-accordion {
  &.uk-accordion > :nth-child(n+2) {
    margin-top: 16px;
  }
  &__item {
    padding: 24px;
    border-radius: 8px;
    background: $light-blue;
    @media screen and (max-width: 959px) {
      padding: 12px 16px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: $font-weight-bold;
    position: relative;
    @media screen and (max-width: 959px) {
      font-size: 16px;
      line-height: 24px;
      padding-right: 32px;
      &::after {
        content: url("../img/icons/arrow-p.svg");
        position: absolute;
        right: 0;
        height: 24px;
        top: calc(50% - 12px);
      }
    }
  }
  .uk-open .about-accordion__title::after {
    transform: rotate(180deg);
  }
  &__icon {
    margin-right: 16px;
    @media screen and (max-width: 959px) {
      max-width: 32px;
    }
    @media screen and (max-width: 639px) {
      display: none;
    }
  }
  &__content {
    padding: 24px 0;
    > :last-child {
      margin-bottom: 0;
    }
    cite {
      font-size: 12px;
      font-style: normal;
    }
    @media screen and (max-width: 959px) {
      padding: 16px 0;
    }
  }
}

.corp {
  &__head {
    padding: 32px 0;
    color: $white;
    background: $purple;
    h1 {
      color: $white;
    }
  }
  &__adv-title {
    font-size: 40px;
    line-height: 56px;
    font-weight: $font-weight-bold;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 2px;
      background: $dark-blue;
    }
    @media screen and (max-width: 959px) {
      font-size: 24px;
      line-height: 32px;
      &::before {
        top: -8px;
      }
    }
    @media screen and (max-width: 639px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__adv-name {
    font-weight: $font-weight-medium;
    @media screen and (max-width: 639px) {
      font-size: 14px;
      text-align: center;
    }
  }
  &__adv-img {
    margin-bottom: 8px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &__banner {
    position: relative;
    background: url("../img/decor/corp-bg.jpg") no-repeat 50% 50% / cover;
    color: $white;
    border-radius: 8px;
    padding: 40px 24px;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    @media screen and (max-width: 639px) {
      padding: 32px 16px;
    }
  }
  &__banner-title {
    font-size: 40px;
    line-height: 48px;
    font-weight: $font-weight-bold;
    margin-bottom: 24px;
    @media screen and (max-width: 959px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }
  &__banner-desc {
    font-weight: $font-weight-medium;
  }
  &__callback {
    padding: 32px;
    border-radius: 8px;
    background: $light-blue;
    .uk-h1 {
      margin-bottom: 8px;
    }
    a {
      font-weight: $font-weight-bold;
      white-space: nowrap;
    }
    @media screen and (max-width: 959px) {
      padding: 24px;
    }
    @media screen and (max-width: 639px) {
      padding: 24px 16px;
    }
  }
  &__deliveries {
    radio:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: $font-weight-medium;
}

.radio {
  input[type="radio"] {
    display: none;
  }
  label {
    position: relative;
    transition: all .3s ease;
    display: block;
    padding-left: 32px;
    cursor: pointer;
    .radio-attr {
      content: '';
      transition: all .3s ease;
      position: absolute;
      left: 4px;
      top: 4px;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $dark-blue;
      &::after {
        content: '';
        position: absolute;
        left: 3px;
        top: calc(50% - 4px);
        width: 8px;
        height: 8px;
        background: $dark-blue;
        border-radius: 50%;
        opacity: 0;
        transition: all .3s ease;
      }
    }
    &:hover {
      color: $blue;
      .radio-attr {
        border-color: $blue;
    }
    }
  }
  input:checked ~ label {
    color: $black;
    cursor: default;
    .radio-attr {
      border-color: $dark-blue;
      &::after {
        opacity: 1;
      }
    }
  }
  input:disabled ~ label {
    color: $gray;
    cursor: default;
    .radio-attr {
      border-color: $gray;

    }
  }
}

.checkbox {
  input[type="checkbox"] {
    display: none;
  }
  label {
    position: relative;
    transition: all .3s ease;
    display: block;
    padding-left: 32px;
    cursor: pointer;
    font-size: 14px;
    .checkbox-attr {
      content: '';
      transition: all .3s ease;
      position: absolute;
      left: 4px;
      top: calc(50% - 9px);
      box-sizing: border-box;
      min-width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid $blue;
      &::after {
        content: '';
        position: absolute;
        left: 2px;
        top: calc(50% - 6px);
        width: 10px;
        height: 6px;
        background: transparent;
        transform: rotate(-45deg);
        border-left: 2px solid $blue;
        border-bottom: 2px solid $blue;
        opacity: 0;
        transition: all .3s ease;
      }
    }
    > a {
      white-space: normal;
      font-weight: $font-weight-regular;
      text-decoration: underline;
      &:hover {
        color: $blue;
      }
    }
    &:hover {
      .checkbox-attr {
        border-color: $blue;
      }
    }
  }
  input:checked ~ label {
    color: $black;
    cursor: default;
    .checkbox-attr {
      border-color: $blue;
      &::after {
        opacity: 1;
      }
    }
  }
}

.brands__tires {
  background: $light-blue;
  padding: 40px 0;
  @media screen and (max-width: 639px) {
    padding: 24px 0 0;
  }
  .uk-h1 {
    margin-bottom: 24px;
  }
}

.tires-list {
  @media screen and (max-width: 639px) {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 124px;
    margin-bottom: -100px;
    padding-right: 16px;
    -webkit-overflow-scrolling: touch;
    > div:last-child {
      padding-right: 16px;
    }
    &__wrap {
      overflow: hidden;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
    }
  }
}

.tires-item {
  display: block;
  background: #FFFFFF;
  border: 1px solid #959FDC;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(92, 103, 170, 0.32), 0px 12px 20px rgba(144, 159, 251, 0.6);
  border-radius: 8px;
  text-align: center;
  padding: 8px 16px 16px;
  transition: all .3s ease;
  @media screen and (max-width: 639px) {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
    img {
      max-width: 136px;
    }
  }
  &__title {
    font-size: 20px;
    line-height: 28px;
    color: $dark-blue;
    font-weight: $font-weight-bold;
    margin-top: 8px;
    white-space: nowrap;
  }
  &:hover {
    transform: scale(1.1);
    text-decoration: none;
  }
}

.brand-item {
  padding: 24px 16px;
  border: 1px solid $gray;
  border-radius: 8px;
  text-align: center;
  &__title {
    display: block;
    margin: 8px 0 24px;
    text-decoration: underline;
    font-weight: $font-weight-medium;
  }
  .sun-button,
  .snow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    border-radius: 8px;
    padding: 0 8px;
    color: $white;
    >img {
      max-width: 24px;
      max-width: 24px;
      height: 24px;
    }
  }
  .sun-button {
    background: $yellow;
  }
  .snow-button {
    background: $blue;
  }
}

.margin-bottom-24-16 {
  margin-bottom: 24px;
  @media screen and (max-width: 959px) {
    margin-bottom: 16px;
  }
}

.margin-bottom-32-16 {
  margin-bottom: 32px;
  @media screen and (max-width: 959px) {
    margin-bottom: 16px;
  }
}

.index-suc {
  &__card {
    position: relative;
    overflow: hidden;
    background: $black;
    max-height: 300px;
    @media screen and (max-width: 639px) {
      width: 256px;
      height: 256px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #000000 100%);
      z-index: 2;
    }
  }
  &__img {
    //position: absolute;
    //left: 0;
    //right: 0;
    //bottom: 0;
    //top: 0;
    width: 100%;
  }
  &__desc {
    position: absolute;
    z-index: 3;
    left: 24px;
    bottom: 32px;
    color: $white;
    font-size: 20px;
    font-weight: $font-weight-bold;
    padding-right: 24px;
    @media screen and (max-width: 1199px) {
      bottom: 24px;
      left: 16px;
    }
  }
}

.index-suc {
  @media screen and (max-width: 639px) {
    &__list {
      flex-wrap: nowrap;
      overflow: auto;
      padding-bottom: 100px;
      margin-bottom: -100px;
      padding-right: 16px;
      -webkit-overflow-scrolling: touch;

      > div:last-child {
        padding-right: 16px;
      }
    }
    &__list-wrap {
      overflow: hidden;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
    }
  }
}

.index-brands {
  background: $blue;
  padding: 64px 0;
  @media screen and (max-width: 959px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 639px) {
    padding: 24px 0;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 127px;
    min-height: 127px;
    box-sizing: border-box;
    padding: 16px;
    background: $white;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: all .3s ease;
    @media screen and (max-width: 959px) {
      max-height: 109px;
      min-height: 109px;
    }
    &:hover {
      border-color: $purple;
      text-decoration: none;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @media screen and (max-width: 639px) {
      margin-bottom: 16px;
    }
    .uk-h1 {
      color: $white;
      margin: 0;
      white-space: nowrap;
    }
    .uk-button {
      margin-left: 32px;
    }
  }
  @media screen and (max-width: 639px) {
    &__list {
      flex-wrap: nowrap;
      overflow: auto;
      padding-bottom: 100px;
      margin-bottom: -100px;
      padding-right: 16px;
      -webkit-overflow-scrolling: touch;

      > div:last-child {
        padding-right: 16px;
      }
    }
    &__list-wrap {
      overflow: hidden;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
    }
  }
  .uk-dotnav a {
    border: 1px solid $white;
  }
  .uk-dotnav > .uk-active > * {
    background-color: $white;
  }
}

.product-card {
  position: relative;
  padding: 4px 16px 16px;
  border: 1px solid transparent;
  &:hover {
    border-color: $dark-blue;
    border-radius: 8px;
  }
  @media screen and (max-width: 639px) {
    padding: 0;
    &:hover {
      border-color: transparent;
    }
  }
  &__img {
    position: relative;
    text-align: center;
    margin-bottom: 4px;
    @media screen and (max-width: 639px) {
      margin-bottom: 8px;
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__title {
    display: block;
    margin-bottom: 8px;
    color: $black;
    &:hover {
      color: $link-blue;
      text-decoration: none;
    }
    @media screen and (max-width: 639px) {
      margin-bottom: 16px;
    }
  }
  &__price {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 639px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
    }
  }
  &__brand {
    font-size: 14px;
    text-decoration: underline;
  }
  &__season {
    position: absolute;
    z-index: 4;
    right: 16px;
    top: 16px;
    max-width: 48px;
    @media screen and (max-width: 639px) {
      right: unset;
      top: 8px;
      left: 8px;
      max-width: 32px;
    }
  }
  .uk-button {
    padding: 0 16px;
    @media screen and (max-width: 639px) {
      font-size: 14px;
    }
  }
}

.cart {
  &__table {
    color: #7B7B7B;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__table-count {
    min-width: 98px;
  }
  &__aside {
    position: sticky;
    top: 24px;
    right: 0;
    padding: 32px 16px;
    border: 1px solid $gray;
    box-shadow: 0px 4px 4px rgba(113, 113, 113, 0.24), 0px 12px 16px rgba(172, 172, 172, 0.6);
    border-radius: 8px;
  }
  &__aside-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    font-size: 24px;
    line-height: 32px;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 40px;
      @media screen and (max-width: 959px) {
        margin-bottom: 32px;
      }
    }
  }
  &__item-img {
    min-width: 64px;
    max-width: 64px;
  }
  &__item-title {
    margin-left: 24px;
    a {
      color: $black;
      transition: all .3s ease;
      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
    @media screen and (max-width: 959px) {
      margin-left: 16px;
    }
  }
  &__item-price {
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }
  &__item-delete {
    display: flex;
    @media screen and (max-width: 959px) {
      flex: 1 1 0;
      justify-content: flex-end;
    }
    a {
      color: $black;
      transition: all .3s ease;
      min-width: 24px;
      &:hover {
        color: red;
      }
    }
  }
  &__clear {
    white-space: nowrap;
    color: red;
    font-size: 14px;
    font-weight: $font-weight-medium;
    display: flex;
    align-items: center;
    transition: all .3s ease;
    &:hover {
      text-decoration: none;
      color: $blue;
    }
    svg {
      min-width: 24px;
      max-width: 24px;
      height: 24px;
    }
  }
}

.cart-count {
  display: flex;
  align-items: center;
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input {
    outline: none;
    width: 50px;
    border: none;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 24px;
    text-align: center;
    -webkit-appearance: none;
    box-shadow: unset;
    -moz-appearance: textfield;
    font-size: 14px;
    font-weight: $font-weight-medium;
  }
  button {
    position: relative;
    min-width: 24px;
    height: 24px;
    padding: 0;
    background-color: $light-blue;
    color: $dark-blue;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    border: none;
    transition: all .3s ease;
    svg {
      min-width: 24px;
      height: 24px;
    }
  }

  button:hover {
    color: $white;
    background: darken($blue, 5%);
  }

  button:active {
    opacity: .5;
  }
}

.checkout {
  &__section {
    background: $light-gray;
    padding: 40px 32px;
    border: 1px solid $link-blue;
    border-radius: 8px;
    margin-bottom: 40px;
    @media screen and (max-width: 959px) {
      padding: 32px 24px;
    }
    @media screen and (max-width: 639px) {
      padding: 24px 16px;
      margin-left: -16px;
      margin-right: -16px;
      border: unset;
      margin-bottom: 32px;
    }
  }
  &__aside {
    position: sticky;
    top: 24px;
  }
  &__list {
    .cart__item:not(:last-child) {
      margin-bottom: 32px;
      @media screen and (max-width: 959px) {
        margin-bottom: 24px;
      }
    }
    .cart__item-title {
      margin-left: 16px;
    }
  }
  &__payment {
    .radio {
      font-weight: $font-weight-bold;
      &:not(:last-child) {
        margin-bottom: 24px;
        @media screen and (max-width: 959px) {
          margin-bottom: 16px;
        }
      }
    }
  }
  &__index {
    @media screen and (min-width: 639px) {
      max-width: 104px;
      min-width: 104px;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    font-size: 20px;
    @media screen and (max-width: 959px) {
      font-size: 16px;
    }
  }
  &__result {
    margin-top: 40px;
    @media screen and (min-width: 959px) {
      .uk-button {
        line-height: 56px;
      }
    }
  }
  &__deliveries {
    margin-top: 32px;
    .radio-delivery-title {
      font-weight: $font-weight-bold;
    }
    .radio-delivery-desc {
      font-size: 14px;
      color: #7B7B7B;
    }
    .radio:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__delivery-types {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  &__delivery-type {
    &:first-child label {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child label {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:not(:last-child) label {
      border-right: unset;
    }
    &:not(:first-child) label {
      border-left: unset;
    }
    input[type='radio'] {
      display: none;
    }
    label {
      display: block;
      padding: 0 32px;
      line-height: 38px;
      background: $white;
      font-weight: $font-weight-medium;
      color: $dark-blue;
      cursor: pointer;
      border: 1px solid $dark-blue;
      @media screen and (max-width: 1199px) {
        padding: 0 16px;
      }
      @media screen and (max-width: 639px) {
        font-size: 14px;
        padding: 0 12px;
      }
    }
    input:checked ~ label {
      cursor: default;
      color: $white;
      background: $blue;
      border-color: transparent;
    }
  }
}

.select-btn {
  border: 1px solid $dark-blue;
  border-radius: 8px;
  span {
    overflow: hidden;
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }
  img {
    min-width: 16px;
    margin-left: 16px;
  }
}

.finish {
  &__about {
    position: sticky;
    top: 24px;
    padding: 24px;
    border: 1px solid $link-blue;
    border-radius: 8px;
    background: $light-gray;
    @media screen and (max-width: 639px) {
      padding: 24px 16px;
    }
    h5 {
      color: #808080;
      font-weight: $font-weight-regular;
      margin-bottom: 4px;
    }
  }
  &__items {
    margin-bottom: 32px;
    .cart__item {
      &:not(:last-child) {
        margin-bottom: 24px;
        @media screen and (max-width: 639px) {
          margin-bottom: 16px;
        }
      }
    }
  }
  &__additional {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    border-top: 1px solid $dark-blue;
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    border-top: 1px solid $dark-blue;
    padding-top: 24px;
    font-size: 24px;
    @media screen and (max-width: 639px) {
      font-size: 20px;
    }
  }
}

.product {
  &__season {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    @media screen and (max-width: 639px) {
      max-width: 32px;
    }
  }
  &__img {
    text-align: center;
    @media screen and (max-width: 639px) {
      img {
        max-height: 240px;
      }
    }
  }
  &__char-key {
    font-size: 14px;
    color: $dark-gray;
    margin-bottom: 4px;
  }
  &__short-char {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      margin-bottom: 16px;
    }
    a {
      text-decoration: underline;
      &:hover {
        color: $purple;
      }
    }
  }
  &__char-link {
    text-decoration: underline;
    &:hover {
      color: $purple;
    }
  }
  &__aside {
    padding: 32px;
    background: #FFFFFF;
    border: 1px solid $gray;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(140, 140, 140, 0.24), 0px 12px 16px rgba(189, 189, 189, 0.6);
    border-radius: 8px;
    @media screen and (max-width: 1199px) {
      padding: 32px 24px;
    }
    @media screen and (max-width: 959px) {
      padding: 24px 16px;
    }
  }
  &__delivery {
    border-radius: 8px;
    border: 1px solid $gray;
    overflow: hidden;
    margin-bottom: 32px;
    .uk-h2 {
      padding: 12px 24px;
      background: $light-blue;
      margin: 0;
      @media screen and (max-width: 1199px) {
        font-size: 20px;
      }
      @media screen and (max-width: 959px) {
        padding: 12px 16px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li {
      padding: 16px 24px;
      @media screen and (max-width: 959px) {
        padding: 12px 16px;
        font-size: 12px;
      }
      &:nth-child(2n) {
        background: $light-gray;
      }
    }
  }
  &__info {
    padding: 32px;
    border: 1px solid $gray;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(140, 140, 140, 0.24), 0px 12px 16px rgba(189, 189, 189, 0.6);
    border-radius: 8px;
    @media screen and (max-width: 1199px) {
      padding: 32px 24px;
    }
    @media screen and (max-width: 639px) {
      padding: 24px 16px;
    }
  }
  &__info-title {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    font-size: 24px;
    line-height: 32px;
    color: $dark-blue;
    margin-bottom: 16px;
    @media screen and (max-width: 1199px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__price {
    font-size: 40px;
    line-height: 1.2;
    font-weight: $font-weight-bold;
    margin-bottom: 24px;
    @media screen and (max-width: 1199px) {
      font-size: 32px;
    }
    @media screen and (max-width: 959px) {
      font-size: 24px;
    }
  }
  &__price-total {
    font-size: 24px;
    font-weight: $font-weight-bold;
    color: $purple;
    @media screen and (max-width: 959px) {
      font-size: 16px;
    }
  }
}

.pagination-wrapper {
  overflow: hidden;
  @media screen and (max-width: 639px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.index-filter {
  background: url("../img/decor/main-bg.jpg") no-repeat 50% 50% / cover;
  padding: 90px 0;
  color: $white;
  @media screen and (max-width: 959px) {
    padding: 48px 0;
  }
  @media screen and (max-width: 639px) {
    padding: 24px 0;
  }
  &__title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: $font-weight-bold;
    margin-bottom: 8px;
    @media screen and (max-width: 959px) {
      font-size: 32px;
    }
    @media screen and (max-width: 639px) {
      font-size: 24px;
    }
  }
  &__categories-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $dark-blue;
    font-weight: $font-weight-bold;
  }
  &__categories {
    display: flex;
    align-items: center;
  }
  &__category {
    input[type='radio'] {
      display: none;
    }
    label {
      display: block;
      line-height: 48px;
      padding: 0 24px;
      background: $white;
      color: $black;
      cursor: pointer;
      border: 1px solid $dark-blue;
      &:hover {
        background: $light-gray;
      }
    }
    &:first-child {
      label {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    &:last-child {
      label {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    input:checked ~ label {
      background: $blue;
      color: $white;
      cursor: default;
      border-color: transparent;
    }
    &:not(:last-child) label {
      border-right: unset;
    }
  }
  &__selects {
    display: flex;
    align-items: center;
    @media screen and (max-width: 959px) {
      flex-direction: column;
      align-items: unset;
    }
    button {
      height: 64px;
      border-radius: 0;
      min-width: 177px;
      max-width: 177px;
      @media screen and (max-width: 959px) {
        max-width: unset;
        height: 48px;
      }
    }
    select:hover ~ .select-btn {
      background: $light-blue;
    }
    .select-btn {
      color: $dark-gray;
      &:hover {
        background: $light-blue;
      }
    }
    > div:first-child {
      button {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        @media screen and (max-width: 959px) {
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
        }
      }
    }
    > div:last-child {
      button {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        @media screen and (max-width: 959px) {
          margin-top: 16px;
          border-radius: 8px;
        }
      }
    }
    > div:not(:last-child) button {
      border-right: unset;
      @media screen and (max-width: 959px) {
        border-right: 1px solid $dark-blue;
        border-bottom: unset;
      }
    }
    > div:nth-last-child(-n+2) button {
      @media screen and (max-width: 959px) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.head-dropdown {
  box-shadow: 0 19px 24px rgba(0, 0, 0, 0.2);
  padding: 40px;
  margin: 0;
  .uk-h3 {
    font-weight: $font-weight-medium;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      a {
        background: transparent;
        color: $link-blue;
        text-decoration: none;
        text-align: initial;
        padding: 0;
        &:hover {
          color: $purple;
        }
      }
    }
  }
}

.modal-template {
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 639px) {
    padding: 24px 16px;
    hr {
      margin-left: -16px;
      margin-right: -16px;
    }
  }
  .uk-button {
    font-size: 16px;
    font-weight: $font-weight-medium;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .modal-close {
      display: block;
      min-width: 24px;
      margin-left: 16px;
    }
    .uk-h1 {
      margin: 0;
      font-weight: $font-weight-bold;
    }
    a {
      color: #000;
      transition: color .3s ease;
      &:hover {
        color: blue;
        text-decoration: none;
      }
    }
  }
}

.modal-cart {
  &-total {
    font-weight: $font-weight-bold;
    font-size: 20px;
  }
  &-del {
    color: $gray;
  }
  .cart__item:not(:last-child) {
    margin-bottom: 24px;
    @media screen and (max-width: 959px) {
      margin-bottom: 16px;
    }
  }

  .cart-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 32px;
    @media screen and (max-width: 640px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

.menuMenu {
  display: -webkit-box;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  width: 100%;
  background: #ffffff;
}

.menuMenu ul {
  list-style: none;
  position: relative;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media screen and (min-width: 639px) {
  .menuMenu {
    width: 340px;
  }
}

.menuMenu li {
  overflow: hidden;
}

.menuMenu li:not(:first-child) {
  padding: 0 16px;
}

.menuMenu li:not(:first-child) {
  margin-bottom: 16px;
}

.menuMenu a {
  color: #262626;
  padding: 6px 12px;
  min-height: 48px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: #E7EAFF;
  display: block;
  border-radius: 8px;
}

.menuMenu .has-subnav > a {
  padding-right: 40px;
}

.menuMenu .has-subnav:last-child {
  margin-top: 16px;
}

.menuMenu .no-subnav > a {
  padding: 0;
  background: transparent;
  margin: 0;
  min-height: 24px;
}

.menuMenu .corp-link > a {
  color: $purple;
}

.menuMenu .menu-header {
  padding: 16px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px;
  box-sizing: border-box;
  height: 56px;
  background: #303F9F;
}

.menuMenu .menu-header a {
  padding: 0;
  min-height: initial;
  background: transparent;
}

.menuMenu .menu-header svg {
  width: initial;
  color: #fff;
  margin-left: 24px;
}

.menuMenu .menu-header h3 {
  color: #262626;
  margin: 0;
}

.menuMenu-body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.menuMenu .main-nav .menu-title {
  border-bottom: none;
  background: $dark-blue;
  display: flex;
  align-items: center;
}

.menuMenu .main-nav .menu-title a {
  background-color: transparent;
  outline: none;
  color: $white;
  font-weight: 500;
}

.menuMenu .main-nav li > a {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.menuMenu .main-nav li.has-subnav > a:before {
  position: absolute;
  display: block;
  content: url("../img/icons/arrow-r.svg");
  height: 24px;
  right: 8px;
  top: calc(50% - 12px);
}

.menuMenu .main-nav li a:hover {
  text-decoration: none;
}

.menuMenu .main-nav_head {
  background: #262626;
}

.menuMenu .main-nav a.back-track {
  padding-left: 48px;
  color: #262626;
  border-radius: unset;
}

.menuMenu .main-nav a.back-track:before {
  position: absolute;
  display: block;
  content: url("../img/icons/arrow-l.svg");
  left: 16px;
  height: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menuMenu .subnav {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 40;
  -webkit-transform: translate3d(101%, 0, 0);
  transform: translate3d(101%, 0, 0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.menuMenu .subnav.active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.menuMenu .main-nav_level-1 {
  overflow-x: hidden;
  width: 100%;
  background-color: #ffffff;
}

.menuMenu .main-nav_level-2 {
  z-index: 80;
  width: 100%;
  background-color: #ffffff;
}

.main-nav_title {
  padding-left: 30px;
}

.menuMenu-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.menuMenu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  z-index: -1;
}

.menuMenu-overlay.menuMenu-overlay_act {
  z-index: 999;
  opacity: 1;
}

.catalog-filter {
  margin-bottom: 32px;
  &__accordion {
    &.uk-accordion > :nth-child(n+2) {
      margin-top: 0;
    }
  }
}

.filter-item {
  display: flex;
  @media screen and (max-width: 639px) {
    > div {
      width: 100%;
    }
  }
  &_check.filter-item {
    .uk-button-default {
      background: $light-blue;
      border-color: $light-blue;
    }
  }
  &__content {
    padding: 16px;
    box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.24), 0px 12px 16px rgba(158, 158, 158, 0.6);
    border-radius: 8px;
    max-width: 260px;
    min-width: 260px;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      overflow-y: auto;
      max-height: 234px;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
        background-color: $blue;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $dark-blue;
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
    li:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__clear {
    padding: 8px;
    line-height: 24px;
    max-height: 40px;
    background: $light-blue;
    color: $dark-blue;
    margin-left: 4px;
    @media screen and (max-width: 639px) {
      display: none;
    }
    &:hover {
      color: $white;
      background: $blue;
    }
    &:active {
      color: $white;
      background: $dark-blue;
    }
    svg, img {
      display: block;
      min-width: 24px;
    }
  }
  .uk-button-default {
    display: flex;
    padding: 0 16px;
    border-color: $gray;
    color: $black;
    font-weight: $font-weight-medium;
    font-size: 14px;
    @media screen and (max-width: 639px) {
      width: 100%;
    }
    span {
      overflow: hidden;
      width: 100%;
      text-align: left;
      white-space: nowrap;
    }
    img {
      min-width: 24px;
    }
    &:hover {
      border-color: $dark-blue;
      color: $dark-blue;
    }
  }
  .uk-button-default.uk-open {
    background: $blue;
    border-color: $blue;
    color: $white;
  }
}

.catalog {
  &__sort-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  &__sort {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    h3 {
      white-space: nowrap;
      margin: 0;
      margin-right: 24px;
      @media screen and (max-width: 959px) {
        font-size: 16px;
        margin-right: 12px;
      }
    }
  }
  &__sort-select {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $dark-blue;
    img {
      max-width: 24px;
      min-width: 24px;
      height: 24px;
    }
    @media screen and (max-width: 639px) {
      img {
        max-width: 18px;
        min-width: 18px;
        height: 18px;
      }
    }
  }
  &__count {
    display: flex;
    align-items: center;
    h4 {
      margin: 0;
      margin-right: 24px;
      @media screen and (max-width: 959px) {
        font-size: 14px;
        margin-right: 16px;
      }
    }
  }
  &__count-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    @media screen and (max-width: 959px) {
      font-size: 14px;
    }
    li:not(:last-child) {
      margin-right: 16px;
    }
    a {
      color: $black;
      &:hover {
        color: $dark-blue;
        text-decoration: underline;
      }
    }
    .active a {
      border-bottom: 2px solid $dark-blue;
      font-weight: $font-weight-bold;
      color: $dark-blue;
      pointer-events: none;
    }
  }
  &__offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: $dark-blue;
    color: $white;
    font-weight: $font-weight-bold;
    a {
      color: $white;
    }
  }
  &__offcanvas-main {
    padding: 32px 16px 0;
    .filter-item {
      max-width: unset;
      &.filter-item_check {
        > div {
          background: $light-blue;
          border-color: $light-blue;
        }
        .uk-button-default {
          background: transparent;
          border-color: unset;
        }
      }
      > div {
        border: 1px solid $gray;
        border-radius: 8px;
      }
      .uk-open .uk-button-default {
        img {
          transform: rotate(180deg);
        }
      }
      .uk-button-default {
        border: none;
        &.uk-open {
          background: transparent;
          border-color: unset;
          color: $black;
        }
      }
    }
    .filter-item__content {
      max-width: unset;
      border-radius: unset;
      box-shadow: unset;
      padding: 12px 16px 16px;
      ul {
        max-height: unset;
      }
    }
  }
  &__offcanvas-bottom {
    padding: 24px 16px;
  }
}
