@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
}

.fwb {
  font-weight: 700;
}

.fwm {
  font-weight: 500;
}

.fz14 {
  font-size: 14px;
}

.fz20 {
  font-size: 20px;
}

.gray-c {
  color: #7B7B7B;
}

.head {
  padding: 16px 0 0;
  font-weight: 500;
}

@media screen and (max-width: 959px) {
  .head {
    background: #303F9F;
    padding: 12px 0;
  }
}

@media screen and (max-width: 959px) {
  .head__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.head__first-block {
  margin-bottom: 24px;
}

@media screen and (max-width: 1199px) {
  .head__first-block {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 959px) {
  .head__first-block {
    margin-bottom: 0;
  }
}

.head__logo {
  display: block;
  color: #262626;
}

.head__logo:hover {
  color: #303F9F;
}

@media screen and (max-width: 1199px) {
  .head__logo {
    max-width: 160px;
  }
}

@media screen and (max-width: 959px) {
  .head__logo {
    color: #ffffff;
    max-width: 107px;
  }
}

.head__short-desc {
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 1199px) {
  .head__short-desc {
    font-size: 16px;
  }
}

@media screen and (max-width: 1099px) {
  .head__short-desc {
    display: none;
  }
}

.head__menu {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.head__menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.head__menu a {
  color: #262626;
}

.head__menu a:hover {
  text-decoration: none;
  color: #303F9F;
}

.head__menu li:not(:last-child) {
  margin-right: 64px;
}

@media screen and (max-width: 1199px) {
  .head__menu li:not(:last-child) {
    margin-right: 48px;
  }
}

.head__menu .corp-link {
  font-weight: 700;
  color: #C22671;
}

.head__contacts {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
}

.head__contacts > li {
  display: flex;
  align-items: center;
}

.head__contacts > li:not(:first-child) {
  margin-left: 48px;
}

@media screen and (max-width: 959px) {
  .head__contacts > li:not(:first-child) {
    margin-left: 24px;
  }
}

.head__link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: all .3s ease;
  color: #303F9F;
}

.head__link:hover {
  text-decoration: none;
  color: #C22671;
}

@media screen and (max-width: 959px) {
  .head__link {
    color: #ffffff;
  }
}

.head__cart {
  position: relative;
}

.head__cart-count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 16px;
  height: 16px;
  right: -8px;
  top: -4px;
  background: #5D70EB;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  overflow: hidden;
}

.head__link-icon {
  min-width: 20px;
  max-width: 20px;
}

@media screen and (max-width: 959px) {
  .head__link-icon {
    min-width: 32px;
    max-width: 32px;
  }
}

.head__catalog {
  margin-top: 20px;
}

.head__catalog-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.head__catalog-list > li {
  flex: 1 1 0;
}

.head__catalog-list a {
  display: block;
  text-align: center;
  padding: 12px 16px;
  background: #303F9F;
  color: #ffffff;
}

.head__catalog-list a:hover {
  text-decoration: none;
}

.head__catalog-list a.uk-open {
  background: #5D70EB;
}

.footer {
  color: #ffffff;
}

.footer__main {
  padding: 40px 0 80px;
  background: #111B59;
}

@media screen and (max-width: 959px) {
  .footer__main {
    padding: 32px 0 40px;
  }
}

.footer__logo {
  color: #5D70EB;
  margin-bottom: 32px;
}

@media screen and (max-width: 639px) {
  .footer__logo {
    max-width: 206px;
  }
}

.footer__menu h3 {
  font-weight: 700;
  margin-bottom: 24px;
  color: #ffffff;
}

.footer__menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__menu li:not(:last-child) {
  margin-bottom: 16px;
}

.footer__menu a {
  color: #ffffff;
}

.footer__contacts {
  padding: 0;
  list-style: none;
}

.footer__contacts li {
  display: flex;
}

.footer__contacts li > img, .footer__contacts li svg {
  min-width: 20px;
  max-width: 20px;
  color: #C22671;
  height: 20px;
}

.footer__contacts li:not(:last-child) {
  margin-bottom: 32px;
}

.footer__contacts a {
  display: flex;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
  color: #ffffff;
}

.footer__contacts a > img, .footer__contacts a svg {
  min-width: 20px;
  max-width: 20px;
  color: #C22671;
  height: 20px;
}

.footer__copyright {
  background: #303F9F;
  padding: 10px 0;
  font-size: 14px;
}

@media screen and (max-width: 959px) {
  .footer__copyright {
    padding: 16px 0;
  }
}

.footer__copyright a {
  text-decoration: underline;
  color: #ffffff;
}

.footer__copyright a:hover {
  text-decoration: none;
}

@media screen and (max-width: 639px) {
  .footer-accordion h3 {
    font-size: 16px;
    border-bottom: 2px solid #E7EAFF;
    margin-bottom: 0;
    line-height: 24px;
    position: relative;
  }
  .footer-accordion h3::after {
    content: url("../img/icons/arrow.svg");
    position: absolute;
    right: 0;
    top: 0;
    height: 24px;
  }
  .footer-accordion .accordion-content {
    padding: 24px 0 8px;
  }
  .footer-accordion .uk-open h3::after {
    transform: rotate(-180deg);
  }
}

.breadcrumbs-wrapper {
  padding: 24px 0;
  overflow: hidden;
}

@media screen and (max-width: 959px) {
  .breadcrumbs-wrapper {
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px 0;
  }
}

@media screen and (max-width: 639px) {
  .breadcrumbs-wrapper {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.page-404__img {
  margin-bottom: 48px;
}

@media screen and (max-width: 639px) {
  .page-404__img {
    margin-bottom: 32px;
  }
}

.page-404__title {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
  color: #303F9F;
  margin-bottom: 16px;
}

@media screen and (max-width: 639px) {
  .page-404__title {
    font-size: 24px;
    line-height: 32px;
  }
}

.page-404__desc {
  margin-bottom: 48px;
}

@media screen and (max-width: 639px) {
  .page-404__desc {
    margin-bottom: 32px;
  }
}

.faq-accordion > li {
  padding: 20px;
  border-radius: 8px;
  background: #E7EAFF;
}

@media screen and (max-width: 639px) {
  .faq-accordion > li {
    padding: 12px 16px;
  }
}

.faq-accordion .uk-accordion-title {
  font-size: 20px;
  font-weight: 700;
  padding-right: 32px;
  position: relative;
}

.faq-accordion .uk-accordion-title::after {
  content: url("../img/icons/arrow-b.svg");
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
  height: 24px;
}

@media screen and (max-width: 639px) {
  .faq-accordion .uk-accordion-title {
    font-size: 16px;
  }
}

.faq-accordion .uk-open .uk-accordion-title::after {
  transform: rotate(-180deg);
}

.faq-accordion .uk-accordion-content {
  padding: 16px 0;
}

.contacts__map {
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid #303F9F;
  overflow: hidden;
  min-height: 410px;
  max-height: 410px;
}

@media screen and (max-width: 639px) {
  .contacts__map {
    min-height: 288px;
    max-height: 288px;
  }
}

.contacts__point h4 {
  color: #303F9F;
  margin-bottom: 0;
}

.contacts__footnote {
  padding: 12px 24px;
  background: #E7EAFF;
  border-radius: 8px;
  margin-top: 24px;
}

@media screen and (max-width: 639px) {
  .contacts__footnote {
    padding: 16px;
    margin-top: 16px;
  }
}

.contacts__item {
  font-size: 20px;
}

.contacts__item a {
  color: #262626;
}

.contacts__item a:hover {
  color: #303F9F;
  text-decoration: none;
}

.columns-2 {
  column-gap: 24px;
  column-count: 2;
}

.columns-2 > * {
  page-break-inside: avoid;
  break-inside: avoid;
}

@media screen and (max-width: 959px) {
  .columns-2 {
    column-count: 1;
  }
}

.about__head {
  position: relative;
  margin-bottom: 24px;
}

@media screen and (max-width: 639px) {
  .about__head {
    margin-bottom: 16px;
  }
}

.about__head::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 37.87%, transparent);
}

.about__head h1 {
  position: absolute;
  left: 40px;
  bottom: 32px;
  z-index: 2;
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 639px) {
  .about__head h1 {
    left: 16px;
    bottom: 20px;
  }
}

.about__banner {
  padding: 80px 64px;
  background: url("../img/decor/about-bg.jpg") no-repeat 75% 50%/cover;
}

@media screen and (max-width: 959px) {
  .about__banner {
    padding: 48px 32px;
  }
}

@media screen and (max-width: 639px) {
  .about__banner {
    padding: 40px 16px;
  }
}

.about__banner-text {
  max-width: 588px;
  color: #ffffff;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

@media screen and (max-width: 959px) {
  .about__banner-text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 639px) {
  .about__banner-text {
    font-size: 14px;
    line-height: 20px;
  }
}

.about-accordion.uk-accordion > :nth-child(n+2) {
  margin-top: 16px;
}

.about-accordion__item {
  padding: 24px;
  border-radius: 8px;
  background: #E7EAFF;
}

@media screen and (max-width: 959px) {
  .about-accordion__item {
    padding: 12px 16px;
  }
}

.about-accordion__title {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  position: relative;
}

@media screen and (max-width: 959px) {
  .about-accordion__title {
    font-size: 16px;
    line-height: 24px;
    padding-right: 32px;
  }
  .about-accordion__title::after {
    content: url("../img/icons/arrow-p.svg");
    position: absolute;
    right: 0;
    height: 24px;
    top: calc(50% - 12px);
  }
}

.about-accordion .uk-open .about-accordion__title::after {
  transform: rotate(180deg);
}

.about-accordion__icon {
  margin-right: 16px;
}

@media screen and (max-width: 959px) {
  .about-accordion__icon {
    max-width: 32px;
  }
}

@media screen and (max-width: 639px) {
  .about-accordion__icon {
    display: none;
  }
}

.about-accordion__content {
  padding: 24px 0;
}

.about-accordion__content > :last-child {
  margin-bottom: 0;
}

.about-accordion__content cite {
  font-size: 12px;
  font-style: normal;
}

@media screen and (max-width: 959px) {
  .about-accordion__content {
    padding: 16px 0;
  }
}

.corp__head {
  padding: 32px 0;
  color: #ffffff;
  background: #C22671;
}

.corp__head h1 {
  color: #ffffff;
}

.corp__adv-title {
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  position: relative;
}

.corp__adv-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 2px;
  background: #303F9F;
}

@media screen and (max-width: 959px) {
  .corp__adv-title {
    font-size: 24px;
    line-height: 32px;
  }
  .corp__adv-title::before {
    top: -8px;
  }
}

@media screen and (max-width: 639px) {
  .corp__adv-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.corp__adv-name {
  font-weight: 500;
}

@media screen and (max-width: 639px) {
  .corp__adv-name {
    font-size: 14px;
    text-align: center;
  }
}

.corp__adv-img {
  margin-bottom: 8px;
}

.corp__adv-img img {
  width: 100%;
  object-fit: cover;
}

.corp__banner {
  position: relative;
  background: url("../img/decor/corp-bg.jpg") no-repeat 50% 50%/cover;
  color: #ffffff;
  border-radius: 8px;
  padding: 40px 24px;
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 639px) {
  .corp__banner {
    padding: 32px 16px;
  }
}

.corp__banner-title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 24px;
}

@media screen and (max-width: 959px) {
  .corp__banner-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.corp__banner-desc {
  font-weight: 500;
}

.corp__callback {
  padding: 32px;
  border-radius: 8px;
  background: #E7EAFF;
}

.corp__callback .uk-h1 {
  margin-bottom: 8px;
}

.corp__callback a {
  font-weight: 700;
  white-space: nowrap;
}

@media screen and (max-width: 959px) {
  .corp__callback {
    padding: 24px;
  }
}

@media screen and (max-width: 639px) {
  .corp__callback {
    padding: 24px 16px;
  }
}

.corp__deliveries radio:not(:last-child) {
  margin-bottom: 16px;
}

.input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.radio input[type="radio"] {
  display: none;
}

.radio label {
  position: relative;
  transition: all .3s ease;
  display: block;
  padding-left: 32px;
  cursor: pointer;
}

.radio label .radio-attr {
  content: '';
  transition: all .3s ease;
  position: absolute;
  left: 4px;
  top: 4px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #303F9F;
}

.radio label .radio-attr::after {
  content: '';
  position: absolute;
  left: 3px;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  background: #303F9F;
  border-radius: 50%;
  opacity: 0;
  transition: all .3s ease;
}

.radio label:hover {
  color: #5D70EB;
}

.radio label:hover .radio-attr {
  border-color: #5D70EB;
}

.radio input:checked ~ label {
  color: #262626;
  cursor: default;
}

.radio input:checked ~ label .radio-attr {
  border-color: #303F9F;
}

.radio input:checked ~ label .radio-attr::after {
  opacity: 1;
}

.radio input:disabled ~ label {
  color: #C1C1C1;
  cursor: default;
}

.radio input:disabled ~ label .radio-attr {
  border-color: #C1C1C1;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox label {
  position: relative;
  transition: all .3s ease;
  display: block;
  padding-left: 32px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox label .checkbox-attr {
  content: '';
  transition: all .3s ease;
  position: absolute;
  left: 4px;
  top: calc(50% - 9px);
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #5D70EB;
}

.checkbox label .checkbox-attr::after {
  content: '';
  position: absolute;
  left: 2px;
  top: calc(50% - 6px);
  width: 10px;
  height: 6px;
  background: transparent;
  transform: rotate(-45deg);
  border-left: 2px solid #5D70EB;
  border-bottom: 2px solid #5D70EB;
  opacity: 0;
  transition: all .3s ease;
}

.checkbox label > a {
  white-space: normal;
  font-weight: 400;
  text-decoration: underline;
}

.checkbox label > a:hover {
  color: #5D70EB;
}

.checkbox label:hover .checkbox-attr {
  border-color: #5D70EB;
}

.checkbox input:checked ~ label {
  color: #262626;
  cursor: default;
}

.checkbox input:checked ~ label .checkbox-attr {
  border-color: #5D70EB;
}

.checkbox input:checked ~ label .checkbox-attr::after {
  opacity: 1;
}

.brands__tires {
  background: #E7EAFF;
  padding: 40px 0;
}

@media screen and (max-width: 639px) {
  .brands__tires {
    padding: 24px 0 0;
  }
}

.brands__tires .uk-h1 {
  margin-bottom: 24px;
}

@media screen and (max-width: 639px) {
  .tires-list {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 124px;
    margin-bottom: -100px;
    padding-right: 16px;
    -webkit-overflow-scrolling: touch;
  }
  .tires-list > div:last-child {
    padding-right: 16px;
  }
  .tires-list__wrap {
    overflow: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
  }
}

.tires-item {
  display: block;
  background: #FFFFFF;
  border: 1px solid #959FDC;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(92, 103, 170, 0.32), 0px 12px 20px rgba(144, 159, 251, 0.6);
  border-radius: 8px;
  text-align: center;
  padding: 8px 16px 16px;
  transition: all .3s ease;
}

@media screen and (max-width: 639px) {
  .tires-item {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
  }
  .tires-item img {
    max-width: 136px;
  }
}

.tires-item__title {
  font-size: 20px;
  line-height: 28px;
  color: #303F9F;
  font-weight: 700;
  margin-top: 8px;
  white-space: nowrap;
}

.tires-item:hover {
  transform: scale(1.1);
  text-decoration: none;
}

.brand-item {
  padding: 24px 16px;
  border: 1px solid #C1C1C1;
  border-radius: 8px;
  text-align: center;
}

.brand-item__title {
  display: block;
  margin: 8px 0 24px;
  text-decoration: underline;
  font-weight: 500;
}

.brand-item .sun-button,
.brand-item .snow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  border-radius: 8px;
  padding: 0 8px;
  color: #ffffff;
}

.brand-item .sun-button > img,
.brand-item .snow-button > img {
  max-width: 24px;
  max-width: 24px;
  height: 24px;
}

.brand-item .sun-button {
  background: #F9A604;
}

.brand-item .snow-button {
  background: #5D70EB;
}

.margin-bottom-24-16 {
  margin-bottom: 24px;
}

@media screen and (max-width: 959px) {
  .margin-bottom-24-16 {
    margin-bottom: 16px;
  }
}

.margin-bottom-32-16 {
  margin-bottom: 32px;
}

@media screen and (max-width: 959px) {
  .margin-bottom-32-16 {
    margin-bottom: 16px;
  }
}

.index-suc__card {
  position: relative;
  overflow: hidden;
  background: #262626;
  max-height: 300px;
}

@media screen and (max-width: 639px) {
  .index-suc__card {
    width: 256px;
    height: 256px;
  }
}

.index-suc__card::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #000000 100%);
  z-index: 2;
}

.index-suc__img {
  width: 100%;
}

.index-suc__desc {
  position: absolute;
  z-index: 3;
  left: 24px;
  bottom: 32px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  padding-right: 24px;
}

@media screen and (max-width: 1199px) {
  .index-suc__desc {
    bottom: 24px;
    left: 16px;
  }
}

@media screen and (max-width: 639px) {
  .index-suc__list {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 100px;
    margin-bottom: -100px;
    padding-right: 16px;
    -webkit-overflow-scrolling: touch;
  }
  .index-suc__list > div:last-child {
    padding-right: 16px;
  }
  .index-suc__list-wrap {
    overflow: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
  }
}

.index-brands {
  background: #5D70EB;
  padding: 64px 0;
}

@media screen and (max-width: 959px) {
  .index-brands {
    padding: 40px 0;
  }
}

@media screen and (max-width: 639px) {
  .index-brands {
    padding: 24px 0;
  }
}

.index-brands__item {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 127px;
  min-height: 127px;
  box-sizing: border-box;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all .3s ease;
}

@media screen and (max-width: 959px) {
  .index-brands__item {
    max-height: 109px;
    min-height: 109px;
  }
}

.index-brands__item:hover {
  border-color: #C22671;
  text-decoration: none;
}

.index-brands__title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 639px) {
  .index-brands__title {
    margin-bottom: 16px;
  }
}

.index-brands__title .uk-h1 {
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
}

.index-brands__title .uk-button {
  margin-left: 32px;
}

@media screen and (max-width: 639px) {
  .index-brands__list {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 100px;
    margin-bottom: -100px;
    padding-right: 16px;
    -webkit-overflow-scrolling: touch;
  }
  .index-brands__list > div:last-child {
    padding-right: 16px;
  }
  .index-brands__list-wrap {
    overflow: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
  }
}

.index-brands .uk-dotnav a {
  border: 1px solid #ffffff;
}

.index-brands .uk-dotnav > .uk-active > * {
  background-color: #ffffff;
}

.product-card {
  position: relative;
  padding: 4px 16px 16px;
  border: 1px solid transparent;
}

.product-card:hover {
  border-color: #303F9F;
  border-radius: 8px;
}

@media screen and (max-width: 639px) {
  .product-card {
    padding: 0;
  }
  .product-card:hover {
    border-color: transparent;
  }
}

.product-card__img {
  position: relative;
  text-align: center;
  margin-bottom: 4px;
}

@media screen and (max-width: 639px) {
  .product-card__img {
    margin-bottom: 8px;
  }
}

.product-card__img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.product-card__title {
  display: block;
  margin-bottom: 8px;
  color: #262626;
}

.product-card__title:hover {
  color: #2C47F0;
  text-decoration: none;
}

@media screen and (max-width: 639px) {
  .product-card__title {
    margin-bottom: 16px;
  }
}

.product-card__price {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

@media screen and (max-width: 639px) {
  .product-card__price {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
}

.product-card__brand {
  font-size: 14px;
  text-decoration: underline;
}

.product-card__season {
  position: absolute;
  z-index: 4;
  right: 16px;
  top: 16px;
  max-width: 48px;
}

@media screen and (max-width: 639px) {
  .product-card__season {
    right: unset;
    top: 8px;
    left: 8px;
    max-width: 32px;
  }
}

.product-card .uk-button {
  padding: 0 16px;
}

@media screen and (max-width: 639px) {
  .product-card .uk-button {
    font-size: 14px;
  }
}

.cart__table {
  color: #7B7B7B;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.cart__table-count {
  min-width: 98px;
}

.cart__aside {
  position: sticky;
  top: 24px;
  right: 0;
  padding: 32px 16px;
  border: 1px solid #C1C1C1;
  box-shadow: 0px 4px 4px rgba(113, 113, 113, 0.24), 0px 12px 16px rgba(172, 172, 172, 0.6);
  border-radius: 8px;
}

.cart__aside-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.cart__item:not(:last-child) {
  margin-bottom: 40px;
}

@media screen and (max-width: 959px) {
  .cart__item:not(:last-child) {
    margin-bottom: 32px;
  }
}

.cart__item-img {
  min-width: 64px;
  max-width: 64px;
}

.cart__item-title {
  margin-left: 24px;
}

.cart__item-title a {
  color: #262626;
  transition: all .3s ease;
}

.cart__item-title a:hover {
  text-decoration: none;
  color: #5D70EB;
}

@media screen and (max-width: 959px) {
  .cart__item-title {
    margin-left: 16px;
  }
}

.cart__item-price {
  font-weight: 700;
  white-space: nowrap;
}

.cart__item-delete {
  display: flex;
}

@media screen and (max-width: 959px) {
  .cart__item-delete {
    flex: 1 1 0;
    justify-content: flex-end;
  }
}

.cart__item-delete a {
  color: #262626;
  transition: all .3s ease;
  min-width: 24px;
}

.cart__item-delete a:hover {
  color: red;
}

.cart__clear {
  white-space: nowrap;
  color: red;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all .3s ease;
}

.cart__clear:hover {
  text-decoration: none;
  color: #5D70EB;
}

.cart__clear svg {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
}

.cart-count {
  display: flex;
  align-items: center;
}

.cart-count input[type='number'] {
  -moz-appearance: textfield;
}

.cart-count input::-webkit-outer-spin-button,
.cart-count input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cart-count input {
  outline: none;
  width: 50px;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 24px;
  text-align: center;
  -webkit-appearance: none;
  box-shadow: unset;
  -moz-appearance: textfield;
  font-size: 14px;
  font-weight: 500;
}

.cart-count button {
  position: relative;
  min-width: 24px;
  height: 24px;
  padding: 0;
  background-color: #E7EAFF;
  color: #303F9F;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all .3s ease;
}

.cart-count button svg {
  min-width: 24px;
  height: 24px;
}

.cart-count button:hover {
  color: #ffffff;
  background: #465ce8;
}

.cart-count button:active {
  opacity: .5;
}

.checkout__section {
  background: #F4F4F4;
  padding: 40px 32px;
  border: 1px solid #2C47F0;
  border-radius: 8px;
  margin-bottom: 40px;
}

@media screen and (max-width: 959px) {
  .checkout__section {
    padding: 32px 24px;
  }
}

@media screen and (max-width: 639px) {
  .checkout__section {
    padding: 24px 16px;
    margin-left: -16px;
    margin-right: -16px;
    border: unset;
    margin-bottom: 32px;
  }
}

.checkout__aside {
  position: sticky;
  top: 24px;
}

.checkout__list .cart__item:not(:last-child) {
  margin-bottom: 32px;
}

@media screen and (max-width: 959px) {
  .checkout__list .cart__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.checkout__list .cart__item-title {
  margin-left: 16px;
}

.checkout__payment .radio {
  font-weight: 700;
}

.checkout__payment .radio:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (max-width: 959px) {
  .checkout__payment .radio:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 639px) {
  .checkout__index {
    max-width: 104px;
    min-width: 104px;
  }
}

.checkout__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
}

@media screen and (max-width: 959px) {
  .checkout__total {
    font-size: 16px;
  }
}

.checkout__result {
  margin-top: 40px;
}

@media screen and (min-width: 959px) {
  .checkout__result .uk-button {
    line-height: 56px;
  }
}

.checkout__deliveries {
  margin-top: 32px;
}

.checkout__deliveries .radio-delivery-title {
  font-weight: 700;
}

.checkout__deliveries .radio-delivery-desc {
  font-size: 14px;
  color: #7B7B7B;
}

.checkout__deliveries .radio:not(:last-child) {
  margin-bottom: 16px;
}

.checkout__delivery-types {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.checkout__delivery-type:first-child label {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.checkout__delivery-type:last-child label {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.checkout__delivery-type:not(:last-child) label {
  border-right: unset;
}

.checkout__delivery-type:not(:first-child) label {
  border-left: unset;
}

.checkout__delivery-type input[type='radio'] {
  display: none;
}

.checkout__delivery-type label {
  display: block;
  padding: 0 32px;
  line-height: 38px;
  background: #ffffff;
  font-weight: 500;
  color: #303F9F;
  cursor: pointer;
  border: 1px solid #303F9F;
}

@media screen and (max-width: 1199px) {
  .checkout__delivery-type label {
    padding: 0 16px;
  }
}

@media screen and (max-width: 639px) {
  .checkout__delivery-type label {
    font-size: 14px;
    padding: 0 12px;
  }
}

.checkout__delivery-type input:checked ~ label {
  cursor: default;
  color: #ffffff;
  background: #5D70EB;
  border-color: transparent;
}

.select-btn {
  border: 1px solid #303F9F;
  border-radius: 8px;
}

.select-btn span {
  overflow: hidden;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}

.select-btn img {
  min-width: 16px;
  margin-left: 16px;
}

.finish__about {
  position: sticky;
  top: 24px;
  padding: 24px;
  border: 1px solid #2C47F0;
  border-radius: 8px;
  background: #F4F4F4;
}

@media screen and (max-width: 639px) {
  .finish__about {
    padding: 24px 16px;
  }
}

.finish__about h5 {
  color: #808080;
  font-weight: 400;
  margin-bottom: 4px;
}

.finish__items {
  margin-bottom: 32px;
}

.finish__items .cart__item:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (max-width: 639px) {
  .finish__items .cart__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.finish__additional {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  border-top: 1px solid #303F9F;
}

.finish__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  border-top: 1px solid #303F9F;
  padding-top: 24px;
  font-size: 24px;
}

@media screen and (max-width: 639px) {
  .finish__total {
    font-size: 20px;
  }
}

.product__season {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 639px) {
  .product__season {
    max-width: 32px;
  }
}

.product__img {
  text-align: center;
}

@media screen and (max-width: 639px) {
  .product__img img {
    max-height: 240px;
  }
}

.product__char-key {
  font-size: 14px;
  color: #7B7B7B;
  margin-bottom: 4px;
}

.product__short-char {
  margin: 0;
  padding: 0;
  list-style: none;
}

.product__short-char li {
  display: flex;
  margin-bottom: 16px;
}

.product__short-char a {
  text-decoration: underline;
}

.product__short-char a:hover {
  color: #C22671;
}

.product__char-link {
  text-decoration: underline;
}

.product__char-link:hover {
  color: #C22671;
}

.product__aside {
  padding: 32px;
  background: #FFFFFF;
  border: 1px solid #C1C1C1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(140, 140, 140, 0.24), 0px 12px 16px rgba(189, 189, 189, 0.6);
  border-radius: 8px;
}

@media screen and (max-width: 1199px) {
  .product__aside {
    padding: 32px 24px;
  }
}

@media screen and (max-width: 959px) {
  .product__aside {
    padding: 24px 16px;
  }
}

.product__delivery {
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  overflow: hidden;
  margin-bottom: 32px;
}

.product__delivery .uk-h2 {
  padding: 12px 24px;
  background: #E7EAFF;
  margin: 0;
}

@media screen and (max-width: 1199px) {
  .product__delivery .uk-h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 959px) {
  .product__delivery .uk-h2 {
    padding: 12px 16px;
  }
}

.product__delivery ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.product__delivery li {
  padding: 16px 24px;
}

@media screen and (max-width: 959px) {
  .product__delivery li {
    padding: 12px 16px;
    font-size: 12px;
  }
}

.product__delivery li:nth-child(2n) {
  background: #F4F4F4;
}

.product__info {
  padding: 32px;
  border: 1px solid #C1C1C1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(140, 140, 140, 0.24), 0px 12px 16px rgba(189, 189, 189, 0.6);
  border-radius: 8px;
}

@media screen and (max-width: 1199px) {
  .product__info {
    padding: 32px 24px;
  }
}

@media screen and (max-width: 639px) {
  .product__info {
    padding: 24px 16px;
  }
}

.product__info-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #303F9F;
  margin-bottom: 16px;
}

@media screen and (max-width: 1199px) {
  .product__info-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.product__price {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 24px;
}

@media screen and (max-width: 1199px) {
  .product__price {
    font-size: 32px;
  }
}

@media screen and (max-width: 959px) {
  .product__price {
    font-size: 24px;
  }
}

.product__price-total {
  font-size: 24px;
  font-weight: 700;
  color: #C22671;
}

@media screen and (max-width: 959px) {
  .product__price-total {
    font-size: 16px;
  }
}

.pagination-wrapper {
  overflow: hidden;
}

@media screen and (max-width: 639px) {
  .pagination-wrapper {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.index-filter {
  background: url("../img/decor/main-bg.jpg") no-repeat 50% 50%/cover;
  padding: 90px 0;
  color: #ffffff;
}

@media screen and (max-width: 959px) {
  .index-filter {
    padding: 48px 0;
  }
}

@media screen and (max-width: 639px) {
  .index-filter {
    padding: 24px 0;
  }
}

.index-filter__title {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 8px;
}

@media screen and (max-width: 959px) {
  .index-filter__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 639px) {
  .index-filter__title {
    font-size: 24px;
  }
}

.index-filter__categories-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #303F9F;
  font-weight: 700;
}

.index-filter__categories {
  display: flex;
  align-items: center;
}

.index-filter__category input[type='radio'] {
  display: none;
}

.index-filter__category label {
  display: block;
  line-height: 48px;
  padding: 0 24px;
  background: #ffffff;
  color: #262626;
  cursor: pointer;
  border: 1px solid #303F9F;
}

.index-filter__category label:hover {
  background: #F4F4F4;
}

.index-filter__category:first-child label {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.index-filter__category:last-child label {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.index-filter__category input:checked ~ label {
  background: #5D70EB;
  color: #ffffff;
  cursor: default;
  border-color: transparent;
}

.index-filter__category:not(:last-child) label {
  border-right: unset;
}

.index-filter__selects {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 959px) {
  .index-filter__selects {
    flex-direction: column;
    align-items: unset;
  }
}

.index-filter__selects button {
  height: 64px;
  border-radius: 0;
  min-width: 177px;
  max-width: 177px;
}

@media screen and (max-width: 959px) {
  .index-filter__selects button {
    max-width: unset;
    height: 48px;
  }
}

.index-filter__selects select:hover ~ .select-btn {
  background: #E7EAFF;
}

.index-filter__selects .select-btn {
  color: #7B7B7B;
}

.index-filter__selects .select-btn:hover {
  background: #E7EAFF;
}

.index-filter__selects > div:first-child button {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media screen and (max-width: 959px) {
  .index-filter__selects > div:first-child button {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
}

.index-filter__selects > div:last-child button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media screen and (max-width: 959px) {
  .index-filter__selects > div:last-child button {
    margin-top: 16px;
    border-radius: 8px;
  }
}

.index-filter__selects > div:not(:last-child) button {
  border-right: unset;
}

@media screen and (max-width: 959px) {
  .index-filter__selects > div:not(:last-child) button {
    border-right: 1px solid #303F9F;
    border-bottom: unset;
  }
}

@media screen and (max-width: 959px) {
  .index-filter__selects > div:nth-last-child(-n+2) button {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.head-dropdown {
  box-shadow: 0 19px 24px rgba(0, 0, 0, 0.2);
  padding: 40px;
  margin: 0;
}

.head-dropdown .uk-h3 {
  font-weight: 500;
}

.head-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.head-dropdown ul li:not(:last-child) {
  margin-bottom: 8px;
}

.head-dropdown ul li a {
  background: transparent;
  color: #2C47F0;
  text-decoration: none;
  text-align: initial;
  padding: 0;
}

.head-dropdown ul li a:hover {
  color: #C22671;
}

.modal-template {
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 639px) {
  .modal-template {
    padding: 24px 16px;
  }
  .modal-template hr {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.modal-template .uk-button {
  font-size: 16px;
  font-weight: 500;
}

.modal-template-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modal-template-header .modal-close {
  display: block;
  min-width: 24px;
  margin-left: 16px;
}

.modal-template-header .uk-h1 {
  margin: 0;
  font-weight: 700;
}

.modal-template-header a {
  color: #000;
  transition: color .3s ease;
}

.modal-template-header a:hover {
  color: blue;
  text-decoration: none;
}

.modal-cart-total {
  font-weight: 700;
  font-size: 20px;
}

.modal-cart-del {
  color: #C1C1C1;
}

.modal-cart .cart__item:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (max-width: 959px) {
  .modal-cart .cart__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.modal-cart .cart-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 32px;
}

@media screen and (max-width: 640px) {
  .modal-cart .cart-total {
    font-size: 16px;
    margin-bottom: 24px;
  }
}

.menuMenu {
  display: -webkit-box;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  width: 100%;
  background: #ffffff;
}

.menuMenu ul {
  list-style: none;
  position: relative;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media screen and (min-width: 639px) {
  .menuMenu {
    width: 340px;
  }
}

.menuMenu li {
  overflow: hidden;
}

.menuMenu li:not(:first-child) {
  padding: 0 16px;
}

.menuMenu li:not(:first-child) {
  margin-bottom: 16px;
}

.menuMenu a {
  color: #262626;
  padding: 6px 12px;
  min-height: 48px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: #E7EAFF;
  display: block;
  border-radius: 8px;
}

.menuMenu .has-subnav > a {
  padding-right: 40px;
}

.menuMenu .has-subnav:last-child {
  margin-top: 16px;
}

.menuMenu .no-subnav > a {
  padding: 0;
  background: transparent;
  margin: 0;
  min-height: 24px;
}

.menuMenu .corp-link > a {
  color: #C22671;
}

.menuMenu .menu-header {
  padding: 16px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px;
  box-sizing: border-box;
  height: 56px;
  background: #303F9F;
}

.menuMenu .menu-header a {
  padding: 0;
  min-height: initial;
  background: transparent;
}

.menuMenu .menu-header svg {
  width: initial;
  color: #fff;
  margin-left: 24px;
}

.menuMenu .menu-header h3 {
  color: #262626;
  margin: 0;
}

.menuMenu-body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.menuMenu .main-nav .menu-title {
  border-bottom: none;
  background: #303F9F;
  display: flex;
  align-items: center;
}

.menuMenu .main-nav .menu-title a {
  background-color: transparent;
  outline: none;
  color: #ffffff;
  font-weight: 500;
}

.menuMenu .main-nav li > a {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.menuMenu .main-nav li.has-subnav > a:before {
  position: absolute;
  display: block;
  content: url("../img/icons/arrow-r.svg");
  height: 24px;
  right: 8px;
  top: calc(50% - 12px);
}

.menuMenu .main-nav li a:hover {
  text-decoration: none;
}

.menuMenu .main-nav_head {
  background: #262626;
}

.menuMenu .main-nav a.back-track {
  padding-left: 48px;
  color: #262626;
  border-radius: unset;
}

.menuMenu .main-nav a.back-track:before {
  position: absolute;
  display: block;
  content: url("../img/icons/arrow-l.svg");
  left: 16px;
  height: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menuMenu .subnav {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 40;
  -webkit-transform: translate3d(101%, 0, 0);
  transform: translate3d(101%, 0, 0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.menuMenu .subnav.active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.menuMenu .main-nav_level-1 {
  overflow-x: hidden;
  width: 100%;
  background-color: #ffffff;
}

.menuMenu .main-nav_level-2 {
  z-index: 80;
  width: 100%;
  background-color: #ffffff;
}

.main-nav_title {
  padding-left: 30px;
}

.menuMenu-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.menuMenu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  z-index: -1;
}

.menuMenu-overlay.menuMenu-overlay_act {
  z-index: 999;
  opacity: 1;
}

.catalog-filter {
  margin-bottom: 32px;
}

.catalog-filter__accordion.uk-accordion > :nth-child(n+2) {
  margin-top: 0;
}

.filter-item {
  display: flex;
}

@media screen and (max-width: 639px) {
  .filter-item > div {
    width: 100%;
  }
}

.filter-item_check.filter-item .uk-button-default {
  background: #E7EAFF;
  border-color: #E7EAFF;
}

.filter-item__content {
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.24), 0px 12px 16px rgba(158, 158, 158, 0.6);
  border-radius: 8px;
  max-width: 260px;
  min-width: 260px;
  overflow: hidden;
}

.filter-item__content ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 234px;
}

.filter-item__content ul::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
  background-color: #5D70EB;
}

.filter-item__content ul::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #303F9F;
}

.filter-item__content ul::-webkit-scrollbar {
  width: 4px;
}

.filter-item__content li:not(:last-child) {
  margin-bottom: 12px;
}

.filter-item__clear {
  padding: 8px;
  line-height: 24px;
  max-height: 40px;
  background: #E7EAFF;
  color: #303F9F;
  margin-left: 4px;
}

@media screen and (max-width: 639px) {
  .filter-item__clear {
    display: none;
  }
}

.filter-item__clear:hover {
  color: #ffffff;
  background: #5D70EB;
}

.filter-item__clear:active {
  color: #ffffff;
  background: #303F9F;
}

.filter-item__clear svg, .filter-item__clear img {
  display: block;
  min-width: 24px;
}

.filter-item .uk-button-default {
  display: flex;
  padding: 0 16px;
  border-color: #C1C1C1;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 639px) {
  .filter-item .uk-button-default {
    width: 100%;
  }
}

.filter-item .uk-button-default span {
  overflow: hidden;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}

.filter-item .uk-button-default img {
  min-width: 24px;
}

.filter-item .uk-button-default:hover {
  border-color: #303F9F;
  color: #303F9F;
}

.filter-item .uk-button-default.uk-open {
  background: #5D70EB;
  border-color: #5D70EB;
  color: #ffffff;
}

.catalog__sort-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.catalog__sort {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.catalog__sort h3 {
  white-space: nowrap;
  margin: 0;
  margin-right: 24px;
}

@media screen and (max-width: 959px) {
  .catalog__sort h3 {
    font-size: 16px;
    margin-right: 12px;
  }
}

.catalog__sort-select {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #303F9F;
}

.catalog__sort-select img {
  max-width: 24px;
  min-width: 24px;
  height: 24px;
}

@media screen and (max-width: 639px) {
  .catalog__sort-select img {
    max-width: 18px;
    min-width: 18px;
    height: 18px;
  }
}

.catalog__count {
  display: flex;
  align-items: center;
}

.catalog__count h4 {
  margin: 0;
  margin-right: 24px;
}

@media screen and (max-width: 959px) {
  .catalog__count h4 {
    font-size: 14px;
    margin-right: 16px;
  }
}

.catalog__count-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 959px) {
  .catalog__count-list {
    font-size: 14px;
  }
}

.catalog__count-list li:not(:last-child) {
  margin-right: 16px;
}

.catalog__count-list a {
  color: #262626;
}

.catalog__count-list a:hover {
  color: #303F9F;
  text-decoration: underline;
}

.catalog__count-list .active a {
  border-bottom: 2px solid #303F9F;
  font-weight: 700;
  color: #303F9F;
  pointer-events: none;
}

.catalog__offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #303F9F;
  color: #ffffff;
  font-weight: 700;
}

.catalog__offcanvas-header a {
  color: #ffffff;
}

.catalog__offcanvas-main {
  padding: 32px 16px 0;
}

.catalog__offcanvas-main .filter-item {
  max-width: unset;
}

.catalog__offcanvas-main .filter-item.filter-item_check > div {
  background: #E7EAFF;
  border-color: #E7EAFF;
}

.catalog__offcanvas-main .filter-item.filter-item_check .uk-button-default {
  background: transparent;
  border-color: unset;
}

.catalog__offcanvas-main .filter-item > div {
  border: 1px solid #C1C1C1;
  border-radius: 8px;
}

.catalog__offcanvas-main .filter-item .uk-open .uk-button-default img {
  transform: rotate(180deg);
}

.catalog__offcanvas-main .filter-item .uk-button-default {
  border: none;
}

.catalog__offcanvas-main .filter-item .uk-button-default.uk-open {
  background: transparent;
  border-color: unset;
  color: #262626;
}

.catalog__offcanvas-main .filter-item__content {
  max-width: unset;
  border-radius: unset;
  box-shadow: unset;
  padding: 12px 16px 16px;
}

.catalog__offcanvas-main .filter-item__content ul {
  max-height: unset;
}

.catalog__offcanvas-bottom {
  padding: 24px 16px;
}
